<template>
  <div class="nav">
    <div class="sticky">
      <div class="head-bg flex items-center">
        <div class="logo">
          <img src="../../assets/navBar/100.png" alt="" />
        </div>
        <div class="head">
          <div class="title flex">
            <p class="left">Bienvenidos&emsp;a</p>
            <p class="right">Calabaza Pets</p>
          </div>
          <div class="nav-bar">
            <div
              class="flex flex-column flex-center align-center"
              :class="{ activeHead: currentPage === '/' }"
              @click="$router.push('/')"
            >
              <div class="navImg">
                <img
                  src="@/assets/navBar/Categoria-active.png"
                  alt=""
                  v-if="currentPage === '/'"
                />
                <img src="@/assets/navBar/Categoria.png" alt="" v-else />
              </div>
              <div class="">Categoria</div>
            </div>
            <div
              class="flex flex-column flex-center align-center"
              :class="{ activeHead: currentPage === '/recommend' }"
              @click="$router.push('/recommend')"
            >
              <div class="navImg">
                <img
                  src="@/assets/navBar/PQR-active.png"
                  alt=""
                  v-if="currentPage === '/recommend'"
                />
                <img src="@/assets/navBar/PQR.png" alt="" v-else />
              </div>
              PQRS
            </div>
            <div
              class="flex flex-column flex-center align-center"
              :class="{ activeHead: currentPage === '/carts' }"
              @click="$router.push('/carts')"
            >
              <div class="navImg">
                <img
                  src="@/assets/navBar/compras-active.png"
                  alt=""
                  v-if="currentPage === '/carts'"
                />
                <img src="@/assets/navBar/compras.png" alt="" v-else />
              </div>

              Carro de compras
            </div>
            <div
              class="flex flex-column flex-center align-center"
              :class="{ activeHead: currentPage === '/mine' }"
              @click="$router.push('/mine')"
            >
              <div class="navImg">
                <img
                  src="../../assets/navBar/Mi-active.png"
                  alt=""
                  v-if="currentPage === '/mine'"
                />
                <img src="../../assets/navBar/Mi.png" alt="" v-else />
              </div>
              Mi cuenta
            </div>
          </div>
        </div>
        <div class="integral_wrapper">
          <div class="integral_container flex flex-col center">
            <div>completo</div>
            <div>{{ integral }}</div>
          </div>
        </div>
      </div>
      <div class="sub-region-bg">
        <div class="sub-region">
          <div class="left">
            <img src="@/assets/navBar/left.png" alt="" />
          </div>
          <div class="search">
            <div class="search-icon" @click="toSearch(value)">
              <img src="@/assets/public/search.png" alt="" />
            </div>
            <div class="input-container flex align-center">
              <el-popover width="600" v-model="hotSearch">
                <div>
                  <div class="hotSearch flex">
                    <div>
                      <img
                        class="img"
                        src="@/assets/home/hotSearch.png"
                        alt=""
                      />
                    </div>
                    <span class="ml5 text">Búsqueda caliente：</span>
                  </div>
                  <div
                    class="flex hotTitle"
                    @click="toSearch(item.name)"
                    v-for="(item, index) in hotWordList"
                    :key="index + 'hot'"
                  >
                    <span class="mlr15">{{ item.name }}</span>
                  </div>
                </div>
                <el-input
                  slot="reference"
                  v-model="value"
                  @change="toSearch($event)"
                  placeholder="Palabras claves de Búsqueda"
                ></el-input>
              </el-popover>
            </div>
          </div>
          <div class="right flex items-center">
            <img src="../../assets/navBar/right.png" alt="" />
           <div class="flex flex-col">
            <div class="integral_wrapper">
              <!-- 取消次数 -->
              <div class="integral_container flex center" style="height: auto; padding: 0 10px">
                <div>Pedidos cancelado</div>
                <div style="margin-left: 10px;">{{ userInfo.cancelOrderCount  || 0 }}</div>
              </div>
            </div>
            <!-- 取消比例 -->
            <div class="integral_wrapper mt-10px">
              <div class="integral_container flex center" style="height: auto; padding: 0 10px">
                <div>Cancelar proporción</div>
                <div style="margin-left: 10px;">{{ userInfo.cancelRadio || 0 }}</div>
              </div>
            </div>
           </div>
          </div>
        </div>
      </div>
    </div>

    <slot></slot>

    <div class="footer">
      <div class="footer-container">
        <div class="logo">
          <img src="@/assets/public/logo-text.png" alt="" />
        </div>
        <div class="footer-nav">
          <ul>
            <li
              :class="{ active: currentPage === '/' }"
              @click="$router.push('/')"
            >
              Categoria
            </li>
            <li
              :class="{ active: currentPage === '/recommend' }"
              @click="$router.push('/recommend')"
            >
              PQRS
            </li>
            <li
              :class="{ active: currentPage === '/carts' }"
              @click="$router.push('/carts')"
            >
              Carro de compras
            </li>
            <li
              :class="{ active: currentPage === '/mine' }"
              @click="$router.push('/mine')"
            >
              Mi cuenta
            </li>
          </ul>
          <ul style="margin-left: 200px">
            <li @click="logout">Cerrar session</li>
            <li @click="$router.push('/register')">Registrarse</li>
            <li
              :class="{ active: currentPage === '/aboutUs' }"
              @click="$router.push('/aboutUs')"
            >
              Acerca de nosotros
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['inputVal'],

  data() {
    return {
      hotSearch: false,
      value: '',
      currentPage: '',
      hotWordList: []
    }
  },

  created() {
    this.getHotList()
    this.value = this.inputVal
    this.currentPage = this.$route.path
    console.log(' this.currentPage', this.currentPage)
    if (!['/carts', '/create-order'].includes(this.currentPage)) {
      this.$store.commit('SET_CHILDREN_ID', '')
    }

    this.getIntegral()
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.app.info,
      integral: (state) => state.app.integral
    })
  },

  methods: {
    getHotList() {
      let data = {
        condition: ''
      }
      this.$axios.post('/keyWord/list', data).then((res) => {
        this.hotWordList = res.data.result.rows
        console.log('res', this.hotWordList)
      })
    },
    logout() {
      this.$confirm('¿Estás seguro de dejar de iniciar sesión?', {
        confirmButtonText: 'Confirmar',
        cancelButtonText: 'Cancelar'
      }).then((res) => {
        localStorage.removeItem('info')
        localStorage.removeItem('token')

        this.$router.push('/login')
      })
    },

    toSearch($event) {
      console.log('sousuo ')
      let currentRoute = this.$route.path

      console.log($event)

      console.log(currentRoute)

      if (currentRoute !== '/search') {
        this.$router.push({
          path: 'search',
          name: 'search',
          params: { searchVal: $event }
        })
      } else {
        this.$emit('isSearch', $event)
      }
    },

    getQueryString(name) {
      return (
        decodeURIComponent(
          (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
            location.href
          ) || [, ''])[1].replace(/\+/g, '%20')
        ) || null
      )
    },

    getIntegral() {
      this.$store.dispatch('getUserIntegral')
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky {
  position: sticky !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 2000;
}
.hotSearch {
  .img {
    width: 12px;
    height: 15px;
  }
  .text {
    color: #282828;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    background: #fff;
    border-radius: 8px;
    // border: 1px solid #E7E7E7;
  }
}
.hotTitle {
  display: inline-block;
  width: auto;
  color: #606060;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  border-right: solid 1px #e7e7e7;
  cursor: pointer;
}
.nav {
  position: relative;
  width: 100vw;
  min-height: calc(100vh - 300px);
  padding-bottom: 300px;
  &::-webkit-scrollbar {
    width: 0;
  }

  .head-bg {
    width: 100%;
    height: 60px;
    background: #00bb05;
    .logo {
      width: 80px;
      height: 80px;
      margin-right: 73px;
      margin-left: 152px;
      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
      }
    }
    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-right: 34px;
      color: #fff;
      font-size: 14px;
      line-height: 44px;
      .nav-bar {
        display: flex;
        > div {
          padding: 0 30px;
          margin-left: 60px;
          color: #fff;
          font-family: LucidaGrande;
          font-size: 12px;
          line-height: 21px;
          white-space: nowrap;
          cursor: pointer;
        }
        > div:hover {
        }
      }
      .title {
        .left {
          margin-right: 14px;
          color: #116200;
          font-family: LucidaGrande;
          font-size: 23px;
          line-height: 35px;
          white-space: nowrap;
        }
        .right {
          color: #fff;
          font-family: LucidaGrande;
          font-size: 23px;
          line-height: 35px;
          white-space: nowrap;
        }
      }
    }
  }

  .sub-region-bg {
    overflow: hidden;
    width: 100%;
    height: 60px;
    background: #88ce65;
    .sub-region {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      padding-left: 82px;
      line-height: 80px;

      .left {
        width: 266px;
        height: 60px;
        img {
          width: 100%;
          height: 100%;

          object-fit: contain;
        }
      }
      .right {
        img {
          width: 237px;
          height: 60px;
          margin-right: 78px;

          object-fit: contain;
        }
      }

      .search {
        display: flex;
        overflow: hidden;
        width: 629px;
        height: 30px;
        padding: 0 20px;
        line-height: 22px;
        background-image: url('../../assets/navBar/searchbg.png');
        background-size: 100% 100%;

        // background: #E1F2D8;
        border-radius: 28px;

        .search-icon {
          width: 18px;
          img {
            width: 100%;
            vertical-align: middle;
          }
        }

        .input-container {
          flex: 1;
          ::v-deep .el-input__inner {
            background: transparent;
            border: none;
            outline: none;
          }
        }
      }

      .shopping-cart {
        width: 158px;
        height: 48px;
        line-height: 48px;
        text-align: center;
        background: #45b035;
        border-radius: 28px;
        cursor: pointer;
        img {
          width: 24px;
          margin-right: 10px;
          vertical-align: middle;
        }
        span {
          color: #fff;
          vertical-align: middle;
        }
      }
    }
  }

  
  .integral_wrapper {
      .integral_container {
        height: 44px;
        background: rgba(255, 255, 255, 0.75);
        border-radius: 38px 0 0 38px;
        padding: 0 20px;
        div {
          color: var(--color-primary);
          font-family: var(--font-family);
          font-size: var(--fs-14);
          font-weight: normal;
          line-height: 19px;
          white-space: nowrap;
        }
      }
    }

  .footer {
    // margin-top: 100px;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    background: #f9fbf9;
    .footer-container {
      display: flex;
      align-items: center;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .logo {
        img {
          width: 227px;
        }
      }
      .footer-nav {
        margin-left: 200px;
        ul {
          display: inline-block;
          vertical-align: text-top;
          li {
            padding: 10px 0;
            font-size: 14px;
            cursor: pointer;
          }

          li:hover {
            color: #8acf66;
          }
        }
      }
    }
  }
}

.activeHead {
  // background: #FFFFFF;
  // color: #45B035 !important;
}
.active {
  color: #45b035;
}
.navImg {
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
}
</style>
